<template>
    <div>
        <!--------------------面包屑导航-------------------->
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item >首页</el-breadcrumb-item>
            <el-breadcrumb-item>基础设置</el-breadcrumb-item>
            <el-breadcrumb-item>出⾏费⽤</el-breadcrumb-item>
        </el-breadcrumb>
        <!----------------------卡片区域---------------------->
        <el-card>
            <div>说明：3公⾥内免出⾏费、3公⾥外每公⾥{{ showAmount  }}元
<!--                、签约助教在签约球房不收费-->
            </div>
            <div style="margin-top: 20px;margin-bottom: 10px;">
                <span>修改出行费用：</span>
                <el-input size="small" placeholder="请输入" v-model="amount" style="width: 320px;">
                    <template slot="prepend">3公⾥外每公⾥</template>
                    <template slot="append">元</template>
                </el-input>
                <el-button size="small" type="primary" @click="submitForm" style="margin-left: 8px;">保 存</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import _ from "lodash";

export default {
    data() {
        return {
            info: {},//当前出行费用信息
            amount: 5,//输入的金额
            showAmount: 5,//显示的金额
        }
    },
    mounted () {
        this.getList()//查列表
    },
    methods: {
        //查列表
        async getList() {
            let data = {
                type: 'taxi_fee',
                pageType: 0,//0不分页 1分页
            }
            let { data: res } = await this.$http.get('/admin/api/get/dict', { params: data})
            this.loading = false;
            if (res.code == 200) {
                if(res.data) {
                    this.info = res.data[0];
                    let amount = Number(res.data[0].name);
                    this.showAmount  = Number(amount) / 100;
                    this.amount  = Number(amount) / 100;
                }
            } else {
                this.$message.error(res.message)
            }
        },
        //保存修改出行费用
        async submitForm() {
            if (this.amount === '') {
                return this.$message.error('请输入价格')
            } else if (!/^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/.test(this.amount)) {
                return this.$message.error('金额格式错误')
            }
            let priceCopy = _.cloneDeep(this.amount)
            if(priceCopy > 999999.99) {
                return this.$message.error('价格不能超过999999.99')
            }
            this.$confirm('', '确认要修改出行费用为3公⾥外每公⾥' + this.amount + '元吗？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                iconClass: 'el-icon-warning',
                closeOnClickModal: false,
                center: true,
                showClose: false,
                beforeClose: async (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        let param = {
                            id: this.info.id,
                            name: (priceCopy * 100).toFixed(0),
                            type: 'taxi_fee',
                        }
                        let res = await this.$http.post('/admin/api/update/dict', param)
                        if (res.data.code === 200) {
                            done();
                            this.$message.success('操作成功！')
                            await this.getList();
                        } else {
                            this.$message.error(res.data.message)
                        }
                        instance.confirmButtonLoading = false;
                    } else {
                        done();
                    }
                }
            }).catch(() => {
                this.$message.info('已取消');
            });
        },
    }
}
</script>

<style scoped>

</style>
